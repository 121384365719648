.programs-header{
    display: flex;
    justify-content: center;
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
}

.plans-card{
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.plans-card>:nth-child(2)>svg{
    fill: white;
}

.plan{
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 2rem;
    width: 18rem;
    border-radius: 5px;
}

.btn{
    border: none;
    height: 2rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 300ms ease;
    border-radius: 5px;
}
.btn:hover{
    color: var(--orange);
    transform: scale(1.1);
}
.plans-card>:nth-child(2)>button{
    color: var(--orange);
}

.plan:nth-child(2){
    transform: scale(1.1);
    background: var(--planCard);
}

.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}

.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(2){
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5){
    font-size: 0.8;
}

.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.feature>img{
    width: 1.5rem;
}

.blur-plans{
    width: 40rem;
    height: 35rem;
    left: 40rem;
}

@media screen and (max-width: 768px){
    .programs-header>p{
        text-align: center;
        justify-content: center;
        font-size: x-large;
        -webkit-text-stroke-width: 1px;
    }
    .plans-card{
        flex-direction: column;
        gap: 3rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        transform: scale(0.7);
        margin: -18rem 0;
    }
}