.Reasons{
    padding: 0 2rem;
    display: flex;
    justify-content: center;
    gap: 5rem;
}

.right>span{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.right>span{
    font-weight: bold;
    color: var(--orange);
}

.right>.question>span{
    text-transform: uppercase;
}

.right>.question{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}

.details{
    text-transform: uppercase;
    font-weight: 500;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.details>div{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.details>div>span{
    font-size: 1.1rem;
}

.partners{
    text-transform: uppercase;
    font-weight: normal;
    color: var(--gray);
}

.images{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 4rem;
    margin-top: 1rem;
}

@media screen and (max-width: 768px){
    .Reasons{
        flex-direction: column;
    }
    .left>img{
        width: 100%;
        height: 100%;
    }

    .right>span{
        font-size: small;
    }

    .question>span{
        font-size: x-large;
    }

    .partners{
        text-align: center;
    }
    .images{
        display: flex;
        flex-direction: row;
        width: 2rem;
    }
}