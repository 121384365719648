.hero{
    display: flex;
    justify-content: space-between;
}

.left-h{
    padding: 1.5rem 0 1.5rem 2rem;
    flex: 3;
    gap: 2rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
}

.right-h{
    flex: 1;
    position: relative;
    background-color: var(--orange);
}

.best-ad{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.best-ad>div{
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}

.best-ad>span{
    z-index: 2;
}

.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}

.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    left: 1px;
    width: 80%;
}

.figures{
    display: flex;
    gap: 2rem;
}

.figures>div{
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
    font-weight: bold;
    display: flex;
} 
.figures>div>span:nth-of-type(2){
    color: VAR(--gray);
    text-transform: uppercase;
}

.button{
    display: flex;
    gap: 1.5rem;
}

.button>button{
    width: 10rem;
    height: 3rem;
    font-size: 15px;
    font-weight: 600;
    color: white;
    cursor: pointer;
}

.button>button:nth-of-type(1){
    background-color: var(--orange);
    border: 2px solid var(--orange);
}
.button>button:nth-of-type(1):hover{
    border: 2px solid white;
}
.button>button:nth-of-type(2){
    background-color: transparent;
    border: 2px solid var(--orange);
}
.button>button:nth-of-type(2):hover{
    background-color: rgba(255, 255, 255, 0.055);
    color: var(--orange);
    border: 2px solid var(--orange);
}

.right-h>button{
    position: absolute;
    right: 1rem;
    top: 2rem;
    color: #000;
}

.right-h>.r-button{
    width: 8rem;
    height: 2.5rem;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    border: none;
}

.right-h>.r-button:hover{
    color: var(--orange);
}

.heartrate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: flex-start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;
}

.heartrate>img{
    width: 2rem;
}

.heartrate>span:nth-of-type(1){
    color: var(--gray);
}
.heartrate>span:nth-of-type(2){
    color: white;
    font-size: 1.5rem;
}

.heroimage{
    position: absolute;
    right: 14rem;
    top: 10rem;
    width: 25rem;
}
.heroimageback{
    position: absolute;
    top: 4rem;
    right: 28rem;
    z-index: -1;
    width: 15rem;
}

.calories{
    display: flex;
    gap: 1rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width: 14rem;
    position: absolute;
    top: 32rem;
    margin-top: 1rem;
    right: 70rem;
}

.calories>img{
    width: 3rem;
}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calories > div > :nth-child(1){
    color: var(--gray);
}
.calories > div > :nth-child(2){
    color: white;
    font-size: 1.5rem;
}

.blur-hero{
    width: 22rem;
    height: 30rem;
    left: 0;
}

@media screen and (max-width: 768px) {
        .hero{
            flex-direction: column;
        }
        .blur-hero{
            width: 14rem;
        }
        .best-ad{
            margin-top: 0;
            font-size: small;
            align-self: center;
            transform: scale(0.8);
        }
        .hero-text{
            font-size: xx-large;
            align-items: center;
            justify-content: center;
        }
        .hero-text > div:nth-of-type(3){
            font-size: small;
            font-weight: 200;
            letter-spacing: 1px;
            text-align: center;
        }

        .figures{
            gap: 1.5rem;
        }

        .figures > div > span:nth-of-type(1){
            font-size: large;
        }
        .figures > div > span:nth-of-type(2){
            font-size: small;
        }

        .button{
            justify-content: center;
        }

        .right-h{
            position: relative;
            background: none;
        }

        .heartrate{
            left: 1rem;
            top: 2rem;
        }
        .calories{
            position: relative;
            top: 5rem;
            left: 1.5rem;
        }
        .calories>img{
            width: 2rem;
        }
        .calories > div > :nth-child(2){
            color: white;
            font-size: 1rem;
        }
        .heroimage{
            position: relative;
            width: 15rem;
            left: 7rem;
            top: 5rem;
            align-self: center;
        }
        .heroimageback{
            width: 15rem;
            left: 3rem;
            top: 0rem;
        }


}